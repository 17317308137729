import React from 'react';
import * as authAction  from '../../actions/authAction';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ListErrors from '../../components/listerrors';
import { trls } from '../../components/translate';
import Pageloadspinner from '../../components/page_load_spinner';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    authLogin: (params) =>
              dispatch(authAction.fetchLoginData(params)),
});

class Login extends React.Component {
  handleSubmit = (event) => {
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    const data = {};

    for (let key of clientFormData.keys()) {
        data[key] = clientFormData.get(key);
    }

    this.props.authLogin(data);
  }

  togglePasswordEye = () => {
    const password = document.querySelector('[name="password"]');
    const passwordEye = document.querySelector('[name="password-eye"]');

    if(password.getAttribute("type") === "password") {
      password.setAttribute("type", "text");
      passwordEye.src = require('../../assets/images/password-eye-show.png');
    } else {
      password.setAttribute("type", "password");
      passwordEye.src = require('../../assets/images/password-eye-hide.png');
    }
  }

  render() {
    return (
      <div className="container p-0 vh-100 bg-white">
        <Row className="h-100">
          <Col xs={12} md={6} className="p-0 position-relative logo-sm h-md-100">
            <img src={require('../../assets/images/line-bg.png')} alt="background" className="loginBackground"/>
            <div className="login-logo">
              <a href="/login">
                <img src={require('../../assets/images/logo.svg')} alt="logo"/>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} className="bg-white">
            <Form className="h-100" onSubmit = { this.handleSubmit }>
              <div className="login__right w-100 h-100 login-page d-flex justify-content-center">
                <div className="login__form-area my-sm-auto w-sm-100 w-md-75 text-left">
                  <h1 className="text-dark">Inloggen</h1>
                  <p className="mb-3">Log hier in als je een account hebt</p>

                  <ListErrors errors={trls(this.props.error)} />

                  <Form.Group controlId="user">
                    <label htmlFor="email" className="mt-4 mt-md-5">{trls('Email')}</label>
                    <Form.Control type="email" name="username" className="form-control form-control-lg" required placeholder={trls("Email")}/>
                  </Form.Group>

                  <Form.Group controlId="pass" className="password-wrapper mb-5">
                      <label htmlFor="password" className="mt-4 mt-md-5">{trls('Password')}</label>
                      <Form.Control type="password" name="password" className="form-control form-control-lg" required placeholder={trls("Password")} />
                      <div className="password-eye" name="toggle-password" onClick={()=>this.togglePasswordEye()}>
                        <img src={require('../../assets/images/password-eye-hide.png')} alt="password-eye" name="password-eye" className="h-100"/>
                      </div>
                  </Form.Group>

                  <Button variant="primary" className="mt-5" size="lg" type="submit" onClick={()=>this.setState({modalResumeShow: true})}>{trls('Sign_in')}</Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        <Pageloadspinner/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
