import React, {Component} from 'react'
import { Row, Col, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import SessionManager from '../../components/session_manage';
import Axios from 'axios';
import API from '../../components/api';
import { trls } from '../../components/translate';
import Updatepurchaseform  from './updatepurchase_form';
import Addpurchaseform  from './addpruchase_form';
import * as Common from '../../components/common';
import FlashMassage from 'react-flash-message';
import Addmanuallytranspor from './transportmanualform';
import Sweetalert from 'sweetalert';

const mapStateToProps = state => ({ 
    ...state,
});

class Purchaseorderdtail extends Component {
    constructor(props) {
        super(props);
        this.state ={
            orderdate: '', 
            salesorderid:'',
            purchaseOrder:[],
            sentToExact: false,
            exactFlag: false,
            sendingFlag: false,
            purchaseOrderLine: [],
            supplierCode: '',
            purchaseTransportManualLine: [],
            totalAmount: 0,
            totalVat: 0,
            totalManualAmount: 0,
            totalManualVat: 0,
            updateManualData: [],
            defaultVatCode: '',
            vatCodeList: [], 
            purchaseOrderDocList: [],
            showTextModal: false,
            vatChanged: false,
            exactButtonDisabled: false
        }
    }

    componentDidMount() {
        Common.showSlideForm();
        this.getPurchaseOrder();
        this.getPurchaseOrderLines();
        this.getPurchaseTransportManual();
    }

        
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    getPurchaseOrder() {
        var params= {
            "purchaseorderid":this.props.newId
        }
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetPurchaseOrder, params, headers)
        .then(result => {
            params = {
                orderid: this.props.newId
            }
            Axios.post(API.GetPurchaseDocuments, params, headers)
            .then(result => {
                if(result.data.Items){
                    this.setState({purchaseOrderDocList: result.data.Items})
                }
            })
            this.setState({purchaseOrder: result.data.Items[0]});
            let purChaseOrder = result.data.Items[0];
            if(!result.data.Items[0].istransport){
                Axios.get(API.GetSuppliersDropdown, headers)
                .then(result => {
                    var suparams = {
                        supplier: purChaseOrder.suppliercode
                    }
                    Axios.post(API.GetDefaultVatCode, suparams, headers)
                    .then(result => {
                        if(result.data.Success){
                            let defaultVatCode = '';
                            if(result.data.Items[0]){
                                defaultVatCode = result.data.Items[0].VatCode;
                            }
                            Axios.get(API.GetVATCode, headers)
                            .then(result => {
                                    let vatCode = result.data.Items.map( s => ({value:s.key,label:s.value}));
                                    var item = vatCode.filter(item => item.value===defaultVatCode);
                                    this.setState({vatCodeList: vatCode, defaultVatCode: item})
                            });
                        }
                    });
                    this.setState({supplierCode: purChaseOrder.suppliercode})
                });
            }else{
                Axios.get(API.GetTransportersDropdown, headers)
                .then(result => {
                    var suparams = {
                        supplier: purChaseOrder.suppliercode
                    }
                    Axios.post(API.GetDefaultVatCode, suparams, headers)
                    .then(result => {
                        if(result.data.Success){
                            let defaultVatCode = '';
                            if(result.data.Items[0]){
                                defaultVatCode = result.data.Items[0].VatCode
                            }
                            Axios.get(API.GetVATCode, headers)
                            .then(result => {
                                    let vatCode = result.data.Items.map( s => ({value:s.key,label:s.value}));
                                    var item = vatCode.filter(item => item.value===defaultVatCode);
                                    this.setState({vatCodeList: vatCode, defaultVatCode: item})
                            });
                        }
                    });
                    this.setState({supplierCode: purChaseOrder.suppliercode})
                });
            }
            this.setState({purchaseOrder: result.data.Items[0]});
        });
    }

    getPurchaseOrderLines () {
        this.setState({ updateManualData: [] });

        const params = {
            purchaseorderid:this.props.newId
        }
        const headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetPurchaseOrderLines, params, headers)
        .then(result => {
            let totalAmount = 0;
            let totalAmountVat = 0;

            result.data.Items.map((data, index)=>{

                if(!this.state.purchaseOrder.istransport){
                    totalAmount += Common.roundFloat(data.amount);
                } else {
                    totalAmount += Common.roundFloat(data.price);
                }

                totalAmountVat += Common.roundFloat(data.btw_amount);
                result.data.Items[index].btw_amount = Common.formatMoney(Common.roundFloat(data.btw_amount));
                
                return data;
            });

            this.setState({purchaseOrderLine: result.data.Items, totalAmount: Common.roundFloat(totalAmount)});
            this.setState({purchaseOrderLine: result.data.Items, totalVat: Common.roundFloat(totalAmountVat)});
        });
    }

    async newProductAddedSetVat(id) {
        const headers = SessionManager.shared().getAuthorizationHeader();
        const getPurchaseParams = { purchaseorderid: this.props.newId };

        // Get PurchaseOrderLines
        const result = await Axios.post(API.GetPurchaseOrderLines, getPurchaseParams, headers);
        const newItem = result.data.Items.find(x => x.id === parseInt(id));

        // Update PurchaseOrderLineVat with the new item
        const vatParams = {
            btw_amount: Common.roundFloat(newItem.btw_amount),
            id: id
        };

        Axios.post(API.PutPurchaseOrderLineVat , vatParams, headers)
        .then(() => {
            return null;
        });
    }

    getPurchaseTransportManual () {
        this.setState({updateManualData: []});

        const params = {
            orderid:this.props.newId
        }
        const headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetPurchaseTransportManual, params, headers)
        .then(result => {
            let totalManualAmount = 0;
            let totalManualVat = 0;

            if(result.data.Items.length){
                result.data.Items.map((data, index)=>{
                    totalManualAmount += Common.roundFloat(data.Amount);
                    totalManualVat += Common.roundFloat(data.btw_amount);
                    result.data.Items[index].btw_amount = Common.formatMoney(Common.roundFloat(data.btw_amount));
                    return data;
                });

                this.setState({purchaseTransportManualLine: result.data.Items, totalManualAmount: Common.roundFloat(totalManualAmount)});
                this.setState({totalManualVat: Common.roundFloat(totalManualVat)});
            }
        });
    }

    getPurchaseTextLines = () => {
        this._isMounted = true;
        var params = {
            purchaseorderheaderid:this.props.newId
        }
        let headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetTextLines, params, headers)
        .then(result => {
            if(this._isMounted) {
                this.setState({purchaseTextLines: result.data.Items});
            }
        });
    }

    generatePurchaseInvoiceXmlExact = () => {
        this.setState({exactButtonDisabled: true});
        this.setState({sendingFlag: true, exactFlag: false})
        let headers = SessionManager.shared().getAuthorizationHeader();
        var params = {
            purchaseid: this.props.newId
        }
        Axios.post(API.PostPurchaseOrderExact, params, headers)
        .then(result => {
            if(result.data.Success){
                this.setState({exactFlag: true, sendingFlag: false});
            }
        });

        this.props.setSent();
    }

    transportManualLineEdit = (data) => {
        this.setState({updateManualData: data, showModalManully: true})
    }

    deletePurchaseTransportManual  = (Id) => {
        let params = {
            id: Id
        }
        let headers = SessionManager.shared().getAuthorizationHeader();
        Sweetalert({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                Axios.post(API.DeletePurchaseTransportManual, params, headers)
                .then(result => {
                    if(result.data.Success){
                        this.getPurchaseTransportManual();
                    }
                });
            }
        });
    }

    deletePurchaseOrderLine = (id) => {
        let headers = SessionManager.shared().getAuthorizationHeader();
        let params = {
            id: id
        }
        Axios.post(API.DeletePurchaseOrderLine, params, headers)
        .then(result => {
            if(result.data.Success){
                this.getPurchaseOrderLines();
            }
        });
    }

    downLoadFile = (fileId) => {
        window.open(API.GetDownloadFile+fileId);
    }

    onHide = () => {
        if(!this.props.viewDetailFlag){
            this.props.onGetgetPurchaseOrders();
        }
        this.props.onHide();
        Common.hideSlideForm(); 
    }

    updownInfo = (id) =>{
        let purchaseOrderLine = this.state.purchaseOrderLine;
        purchaseOrderLine.map((data)=>{
            if(data.id===id){
                if(!data.checked){

                    data.checked = true;
                }else{
                    data.checked = false;
                }
            }
            return data;
        })
        this.setState({purchaseOrderLine: purchaseOrderLine});
    }

    newOrderRegistrate = (orderHeaderId) => {
        var re = new RegExp(/^.*\//);
        let path = re.exec(window.location.href);
        window.open(path + 'sales-order/' + orderHeaderId, '_blank');

    }

    deleteSalesOrderDocment = (fileId) => {
        let params = {
            filestorageid: fileId
        }
        const header = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.DeletePurchaseDocument, params, header)
        .then(result=>{
            if(result.data.Success){
                this.getPurchaseOrder();
            }
        })
    }

    checkVatInput = (event) => {
        const currentString = event.target.value;
        const currentChar = String.fromCharCode(event.charCode);
        let finalString = event.target.value + String.fromCharCode(event.charCode);
        finalString = finalString.replace("€", "");
        finalString = finalString.replace(".", "");
        finalString = finalString.replace(",", "");
        finalString = finalString.replace(" ", "");

        if(currentString.includes("€") && currentChar === "€") {
            event.preventDefault()
        } else if(currentString.includes(".") && currentChar === ".") {
            event.preventDefault();
        } else if(currentString.includes(".") && currentChar === ",") {
            event.preventDefault();
        } else if(currentString.includes(",") && currentChar === ".") {
            event.preventDefault();
        } else if(currentString.includes(",") && currentChar === ",") {
            event.preventDefault();
        }
        
        if(!isNaN(finalString)) {
            return;
        }

        event.preventDefault()
    }

    btwValueKeyDown = (event, index, manual) => {   
        if(event.key === "Enter") {
            event.target.disabled = true;
            event.target.disabled = false;
            
            this.btwValueUpdate(event, index, manual);
        }
    }

    euroToFloat = (value) => {
        let newFloat = value;
        newFloat = newFloat.replace("€", "");
        newFloat = newFloat.replace(" ", "");
        newFloat = newFloat.replace(",", ".");

        return newFloat;
    }

    btwValueChange = (event, index, manual) => {
        const btwValue = event.target.value;
        
        if(manual) {
            const items = [...this.state.purchaseTransportManualLine];
            items[index].btw_amount = btwValue;
        
            this.setState({
                purchaseTransportManualLine: items,
            });
        } else {
            const items = [...this.state.purchaseOrderLine];
            items[index].btw_amount = btwValue;
        
            this.setState({
                purchaseOrderLine: items,
            });
        }
        
        this.setState({ vatChanged: true });
    }

    btwValueUpdate = (event, index, manual) => {
        if(!this.state.vatChanged) return;
        const btwValue = this.euroToFloat(event.target.value);

        if(!isNaN(btwValue)) {
            const params = {
                btw_amount: btwValue
            };

            if(manual) {
                params.id = this.state.purchaseTransportManualLine[index].Id;
            } else {
                params.id = this.state.purchaseOrderLine[index].id;
            }

            const header = SessionManager.shared().getAuthorizationHeader();
            Axios.post(API.PutPurchaseOrderLineVat , params, header)
            .then(result=>{
                if(result.data.Success) {
                    this.getPurchaseOrder();
                    this.getPurchaseTransportManual();
                    this.getPurchaseOrderLines();

                    Sweetalert({
                        title: trls('VATAmount') + " " + trls('Saved').toLowerCase(),
                        icon: "success",
                        buttons: false,
                        dangerMode: false,
                        className: "py-3",
                        timer: 850
                    });

                    this.setState({ vatChanged: false });
                }
            })
        }
    }

    render () {
        let detailData = [];
        if(this.state.purchaseOrder){
            detailData = this.state.purchaseOrder;
        }

        const { purchaseOrderDocList } = this.state;
        
        return (
            <div className = "slide-form__controls open slide-product__detail">
                <div style={{marginBottom:30, padding:"0 20px"}}>
                    <i className="fas fa-times slide-close" style={{ fontSize: 20, cursor: 'pointer'}} onClick={()=>this.onHide()}></i>
                </div>
                {this.state.exactFlag&&(
                    <div>
                        <FlashMassage duration={2000}>
                            <div className="alert alert-success" style={{marginTop:10}}>
                                <strong><i className="fas fa-check-circle"></i> Success!</strong>
                            </div>
                        </FlashMassage>
                    </div>
                )
                }
                {this.state.sendingFlag&&(
                    <div style={{marginTop:10}}><Spinner animation="border" variant="info"/><span style={{marginTp:10, fontWeight: "bold", fontSize: 16}}> {trls('Sending')}...</span></div>
                )}
                <div className="place-and-orders__top">
                    <Row className="product-detail__data-div">
                        <Col sm={6}>
                            <Col style={{padding: 0}}>
                                <Form.Control type="text" readOnly defaultValue={detailData.Customer}/>
                                <label className="placeholder-label purhcase-placeholder">{trls('Supplier')}</label>
                            </Col>
                            <Col style={{display: 'flex', justifyContent: "space-between", padding: "20px 0px" }}>
                                <Col style={{paddingRight: 10, paddingLeft: 0}}>
                                    <Form.Control type="text" readOnly defaultValue={detailData.invoicenr}/>
                                    <label className="placeholder-label purhcase-placeholder">{trls('Invoice')}</label>
                                </Col>
                                <Col style={{paddingLeft: 10, paddingRight: 0}}>
                                    <Form.Control type="text" readOnly defaultValue={detailData.invoicedate ? Common.formatDate(detailData.invoicedate) : ''}/>
                                    <label className="placeholder-label" style={{left: "1.5em"}}>{trls('Invoice_date')}</label>
                                </Col>
                            </Col>
                            <Col style={{padding: 0}}>
                                <Form.Control type="text" readOnly defaultValue={detailData.description}/>
                                <label className="placeholder-label purhcase-placeholder">{trls('Description')}</label>
                            </Col>
                            <Col style={{padding: "20px 0px"}}>
                                {detailData &&(
                                    <Form.Check type="checkbox" label={trls("IsTransport")} disabled  defaultChecked={detailData.istransport} name="transport" />
                                )}
                            </Col>
                            <Col style={{padding: "20px 0px", paddingTop: 0}}>
                                <div id="react-file-drop-demo" className = "purhcase-order__doc">
                                    {purchaseOrderDocList.length>0&&(
                                        purchaseOrderDocList.map((data,i) =>(
                                            <div id={i} key={i}>
                                                <span className="docList-text" onClick={()=>this.downLoadFile(data.FileStorageId)}>{data.FileName}</span>
                                                <i className="fas fa-trash-alt add-icon doclist-delete__icon" onClick={()=>this.deleteSalesOrderDocment(data.FileStorageId)}></i>
                                            </div>
                                        ))
                                    )
                                    }
                                </div>
                                <label className="placeholder-label_purchase purhcase-placeholder">{trls('File')}</label>
                            </Col>
                            <div className="d-flex flex-row">
                                    <button type="button" className="btn btn-primary-lg d-flex justify-content-center align-items-center" style={{ backgroundColor: "#FFDB2F"}} onClick={()=>this.setState({modalShow:true, exactFlag: false})}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                    <OverlayTrigger
                                    key={"exact"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"exact"}`}>
                                        {trls("Send_to_Exact")}
                                        </Tooltip>
                                    }
                                    >
                                        <button type="button" className="btn btn-primary-lg ml-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#FFDB2F"}} title="Exact" onClick={()=>this.generatePurchaseInvoiceXmlExact()} disabled={this.props.sentToExact === 1 || this.state.exactButtonDisabled}><img src={require("../../assets/images/exact.png")} className="img-icon" alt="edit"/></button>
                                    </OverlayTrigger> 
                                </div>
                        </Col>
                    </Row>
                </div>
                <div className="purchase-amount bg-blue" style={{textAlign: 'right', fontWeight: 'bold', padding: 10, marginBottom: 20, marginTop: 10}}>
                    <div className="d-flex justify-content-between">
                        <span>{trls('TotalExcl')} {Common.formatMoney(parseFloat(this.state.totalAmount) + parseFloat(this.state.totalManualAmount))}</span>
                        <span>{trls('TotalVat')} {Common.formatMoney(parseFloat(this.state.totalVat) + parseFloat(this.state.totalManualVat))}</span>
                        <span>{trls('TotalIncl')} {Common.formatMoney(parseFloat(this.state.totalAmount) + parseFloat(this.state.totalManualAmount) + parseFloat(this.state.totalVat) + parseFloat(this.state.totalManualVat))}</span>
                    </div>
                </div>
                <div className="product-detail-table">
                    <div className="product-price-table">
                        <div className="purchase-price__div">
                            <p className="purprice-title"><i className="fas fa-caret-right add-icon" style={{color: "#2CB9D9"}}></i>{trls("Products")}</p>
                            <Button variant="primary" className="btn-icon" style={{marginLeft: "auto"}} onClick={()=>this.setState({showModalPurchaase: true})}><i className="fas fa-plus add-icon"></i></Button>
                        </div>
                        <div className="table-responsive prurprice-table__div mt-0 px-0">
                            {!this.state.purchaseOrder.istransport ? (
                                    <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable" width="100%">
                                        <thead>
                                            <tr>
                                                <th>{trls('Product')}</th>
                                                <th>{trls('Quantity')}</th>
                                                <th>{trls('Purchase_Unit')}</th>
                                                <th>{trls('Price')}</th>
                                                <th>{trls('Amount')}</th>
                                                <th>{trls('VATAmount')}</th>
                                                <th>{trls('Reporting_Date')}</th>
                                                <th>{trls('Action')}</th>
                                                </tr>
                                        </thead>
                                        {this.state.purchaseOrderLine && (<tbody>
                                            {
                                                this.state.purchaseOrderLine.map((data,i)=>(
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td className={data.checked ? "order-product__td order-product__first-td" : ''}>{data.productcode}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>{data.quantity}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>{data.unit}</td>
                                                            <td className={data.checked ? "order-product__td" : 'text-nowrap'}>{Common.formatMoney(data.price)}</td>
                                                            <td className={data.checked ? "order-product__td" : 'text-nowrap'}>{Common.formatMoney(data.amount)}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>
                                                                <span className="currency d-flex align-items-center">
                                                                    <input type="text" id="totalVat" className="form-control currency__input" value={data.btw_amount} onKeyPress={(e) => this.checkVatInput(e)} onKeyDown={(e) => this.btwValueKeyDown(e, i)} onBlur={(e) => this.btwValueUpdate(e, i)} onChange={(e) => this.btwValueChange(e, i)}></input>                   
                                                                </span>
                                                            </td>
                                                            <td className={data.checked ? "order-product__td" : ''}>{Common.formatDate(data.reportingdate)}</td>
                                                            <td className={data.checked ? "order-product__td order-product__last-td" : ''}>
                                                                <Row style={{width: 150}} className="d-flex justify-content-between">
                                                                    <button type="button" className="delete-btn mr-2 my-1" onClick={()=>this.deletePurchaseOrderLine(data.id)}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                                    <button type="button" className="edit-btn my-1" onClick={()=>this.newOrderRegistrate(data.SalesOrderHeaderId)}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                                                    <Button className="price-action__button mt-0" variant="light" onClick={()=>this.updownInfo(data.id)}><i className={data.checked ? "fas fa-caret-up" : "fas fa-caret-down"}></i></Button>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        {data.checked && (
                                                            <tr>
                                                                <td className={data.checked ? "order-product__first-td" : ''}></td>
                                                                <td>
                                                                    <div>{trls("Packing_slip_number")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.PackingSlip}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{trls("Container_number")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.Container}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{trls("ShippingDocumentnumber")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.Shipping}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{trls('VATCode')}</div>
                                                                    <div style={{paddingTop: 20}}>{data.VAT}</div>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className={data.checked ? "order-product__last-td" : ''}></td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            }
                                            <tr style={{fontWeight: 'bold'}} className="bg-lightblue">
                                                <td colSpan={8} style={{textAlign: 'right'}}>
                                                    <div className="d-flex justify-content-between purchase-child-total">
                                                        <span>{trls('TotalExcl')} {Common.formatMoney(parseFloat(this.state.totalAmount))}</span>
                                                        <span>{trls('TotalVat')} {Common.formatMoney(parseFloat(this.state.totalVat))}</span>
                                                        <span>{trls('TotalIncl')} <u>{Common.formatMoney(parseFloat(this.state.totalVat) + parseFloat(this.state.totalAmount))}</u></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>)}
                                    </table>
                                ):
                                    <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable" width="100%">
                                        <thead>
                                            <tr>
                                                <th>{trls('Pricingtype')}</th>
                                                <th>{trls('Price')}</th>
                                                <th>{trls('VATCode')}</th>
                                                <th>{trls('VATAmount')}</th>
                                                <th>{trls('Action')}</th>
                                            </tr>
                                        </thead>
                                        {this.state.purchaseOrderLine && (<tbody>
                                            {
                                                this.state.purchaseOrderLine.map((data,i)=>(
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td className={data.checked ? "order-product__td order-product__first-td" : ''}>{data.pricingtype}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>{Common.formatMoney(data.price)}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>{data.VAT}</td>
                                                            <td className={data.checked ? "order-product__td" : ''}>
                                                                <span className="currency d-flex align-items-center">
                                                                    <input type="text" id="totalVat" className="form-control currency__input" value={data.btw_amount} onKeyPress={(e) => this.checkVatInput(e)} onKeyDown={(e) => this.btwValueKeyDown(e, i)} onBlur={(e) => this.btwValueUpdate(e, i)} onChange={(e) => this.btwValueChange(e, i)}></input>                   
                                                                </span>
                                                            </td>
                                                            <td className={data.checked ? "order-product__td order-product__last-td" : ''}>
                                                                <Row style={{justifyContent:"between", width: 150}}>
                                                                    <button type="button" className="delete-btn mr-2 my-1" onClick={()=>this.deletePurchaseOrderLine(data.id)}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                                    <Button className="price-action__button" variant="light" onClick={()=>this.updownInfo(data.id)}><i className={data.checked ? "fas fa-caret-up" : "fas fa-caret-down"}></i></Button>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        {data.checked && (
                                                            <tr>
                                                                <td>
                                                                    <div>{trls("Packing_slip_number")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.PackingSlip}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{trls("Container_number")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.Container}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{trls("ShippingDocumentnumber")}</div>
                                                                    <div style={{paddingTop: 20}}>{data.Shipping}</div>
                                                                </td>
                                                                <td className={data.checked ? "order-product__last-td" : ''}></td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            }
                                            <tr style={{fontWeight: 'bold'}} className="bg-lightblue">
                                                <td colSpan={8} style={{textAlign: 'right'}}>
                                                    <div className="d-flex justify-content-between purchase-child-total">
                                                        <span>{trls('TotalExcl')} {Common.formatMoney(parseFloat(this.state.totalAmount))}</span>
                                                        <span>{trls('TotalVat')} {Common.formatMoney(parseFloat(this.state.totalVat))}</span>
                                                        <span>{trls('TotalIncl')} <u>{Common.formatMoney(parseFloat(this.state.totalVat + this.state.totalAmount))}</u></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>)}
                                    </table>
                                }
                        </div>
                    </div>
                    <div className="product-price-table mt-5">
                        <div className="purchase-price__div">
                            <p className="purprice-title"><i className="fas fa-caret-right add-icon" style={{color: "#2CB9D9"}}></i>{trls("Custom_products")}</p>
                            <Button variant="primary" className="btn-icon" style={{marginLeft: "auto"}} onClick={()=>this.setState({showModalManully: true})}><i className="fas fa-plus add-icon"></i></Button>
                        </div>
                        <div className="table-responsive prurprice-table__div mt-0 px-0">
                        <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable" width="100%">
                                <thead>
                                    <tr>
                                        <th>{trls('Product')}</th>
                                        <th>{trls('Quantity')}</th>
                                        <th>{trls('Price')}</th>
                                        <th>{trls('Amount')}</th>
                                        <th>{trls('VATAmount')}</th>
                                        <th>{trls('Reporting_Date')}</th>
                                        <th>{trls('Action')}</th>
                                    </tr>
                                </thead>
                                {this.state.purchaseTransportManualLine && (<tbody>
                                    {
                                        this.state.purchaseTransportManualLine.map((data,i) =>(
                                        <tr id={data.Id} key={i}>
                                            <td>{data.Product}</td>
                                            <td>{data.Quantity}</td>
                                            <td className="text-nowrap">{Common.formatMoney(data.Price)}</td>
                                            <td className="text-nowrap">{Common.formatMoney(data.Amount)}</td>
                                            <td className={data.checked ? "order-product__td" : ''}>
                                                <span className="currency">
                                                    <input type="text" id="totalVat" className="form-control currency__input" value={data.btw_amount} onKeyPress={(e) => this.checkVatInput(e)} onKeyDown={(e) => this.btwValueKeyDown(e, i, true)} onBlur={(e) => this.btwValueUpdate(e, i, true)} onChange={(e) => this.btwValueChange(e, i, true)}></input>                   
                                                </span>
                                            </td>
                                            <td>{Common.formatDate(data.ReportingDate)}</td>
                                            <td >
                                            <div className="d-flex justify-content-between">
                                                <button type="button" className="delete-btn mr-2 my-1" onClick={()=>this.deletePurchaseTransportManual(data.Id)}><img src={require("../../assets/images/delete.svg")} alt="delete"/></button>
                                                <button type="button" className="edit-btn mr-2 my-1" onClick={()=>this.transportManualLineEdit(data)}><img src={require("../../assets/images/edit.svg")} alt="edit"/></button>
                                            </div>
                                        </td>
                                        </tr>
                                    ))
                                    }
                                    <tr style={{fontWeight: 'bold'}} className="bg-lightblue">
                                        <td colSpan={8} style={{textAlign: 'right'}}>
                                            <div className="d-flex justify-content-between purchase-child-total">
                                                <span>{trls('TotalExcl')} {Common.formatMoney(parseFloat(this.state.totalManualAmount))}</span>
                                                <span>{trls('TotalVat')} {Common.formatMoney(parseFloat(this.state.totalManualVat))}</span>
                                                <span>{trls('TotalIncl')} <u>{Common.formatMoney(parseFloat(this.state.totalManualVat + this.state.totalManualAmount))}</u></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>)}
                            </table>
                        </div>
                    </div>
                </div>
                <div className="purchase-amount bg-blue" style={{textAlign: 'right', fontWeight: 'bold', padding: 10, marginBottom: 20, marginTop: 10}}>
                    <div className="d-flex justify-content-between">
                        <span>{trls('TotalExcl')} {Common.formatMoney(parseFloat(this.state.totalAmount) + parseFloat(this.state.totalManualAmount))}</span>
                        <span>{trls('TotalVat')} {Common.formatMoney(parseFloat(this.state.totalVat) + parseFloat(this.state.totalManualVat))}</span>
                        <span>{trls('TotalIncl')} {Common.formatMoney(parseFloat(this.state.totalAmount) + parseFloat(this.state.totalManualAmount) + parseFloat(this.state.totalVat) + parseFloat(this.state.totalManualVat))}</span>
                    </div>
                </div>
                {this.state.purchaseOrder.id && (
                    <Updatepurchaseform
                        show={this.state.modalShow}
                        onHide={() => this.setState({modalShow: false})}
                        purchaseData={this.state.purchaseOrder}
                        getPurchaseOrder={()=>this.getPurchaseOrder()}
                        newProductAddedSetVat={(val)=>this.newProductAddedSetVat(val)}
                    />
                )}
                <Addpurchaseform
                    show={this.state.showModalPurchaase}
                    onHide={() => this.setState({showModalPurchaase: false})}
                    suppliercode={this.state.supplierCode}
                    purchaseid={this.props.newId}
                    defaultVatCode={this.state.defaultVatCode}
                    vatCodeList={this.state.vatCodeList}
                    getPurchaseOrderLines={()=>this.getPurchaseOrderLines()}
                    transport={this.state.purchaseOrder.istransport}
                    newProductAddedSetVat={(val)=>this.newProductAddedSetVat(val)}
                />
                <Addmanuallytranspor
                    show={this.state.showModalManully}
                    onHide={() => this.setState({showModalManully: false})}
                    reportingDate={detailData.invoicedate}
                    orderid={this.props.newId}
                    getPurchaseTransportManual={()=>this.getPurchaseTransportManual()}
                    updateData={this.state.updateManualData}
                />
            </div>
        )
    }
}
export default connect(mapStateToProps)(Purchaseorderdtail);