import React, {Component} from 'react'
import * as authAction  from '../actions/authAction';
import { connect } from 'react-redux';
import history from '../history';
import { removeAuth } from '../components/auth';
import * as Auth from '../components/auth';
import $ from 'jquery';

const mapStateToProps = state => ({ 
    ...state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    changeLan: (params) =>
        dispatch(authAction.changeLan(params)),
});
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            roles:[{"value":"en_US","label":"En"},{"value":"nl_BE","label":"Nl"}],
            selectrolvalue:window.localStorage.getItem('nevema_lang'),
            selectrollabel:window.localStorage.getItem('nevema_label'),
            userInfo: Auth.getUserInfo()
        };
    }
    componentDidMount () {
        $(".header__burger-btn").click(function() {
            $(".header__burger-btn").toggleClass("open")
            $(".sidebar").toggleClass("open");
        })
        $(".header__user").click(function() {
            $(".header__controls").toggleClass("open")
        })
    }
    logOut = () => {
        var removeFlag = removeAuth();
        if(removeFlag){
            history.push('/login')
        }
    }
    changeLangauge = (val) => {
        this.setState({selectrolvalue:val.value, selectrollabel: val.label});
        this.props.changeLan(val)
    }
    render () {
        return (
            <div className="row p-20">
                <div className="col-8 d-xl-none">
                    <a href="/">
                        <img src={require("../assets/images/logo2.png")} className="d-xl-none header-logo" alt="logo"/>
                    </a>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center d-xl-none">
                    <div className="header__burger-btn float-right text-dark">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        )
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Header);
