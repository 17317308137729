//let BASEURL = 'https://app1.organisatie.freepeat.com:4434'; //PRODUCTION
let BASEURL = 'https://app-test.organisatie.freepeat.com'; //DEVELOP


export default {
    GetToken: `${BASEURL}/token`,
    GetUserData: `${BASEURL}/api/users/GetUsers?excludeActiveUsers=false`,
    PostUserData: `${BASEURL}/api/Users`,
    DeleteUserData: `${BASEURL}/api/users/Delete?id=`,
    GetUserDataById: `${BASEURL}/api/Users/`,
    PostUserUpdate: `${BASEURL}/api/users/Update`,
    PostProductData: `${BASEURL}/api/factory/execute/Appmakerz-Test/postProduct`,
    PostCopyProduct: `${BASEURL}/api/factory/execute/Appmakerz-Test/copyProduct`,
    GetProductData: `${BASEURL}/api/factory/execute/Appmakerz-Test/getProducts`,
    GetSupplierList: `${BASEURL}/api/factory/execute/Exact-Test/getSuppliersDropdown`,
    GetroductType: `${BASEURL}/api/factory/execute/Appmakerz-Test/getProductTypesDropdown`,
    GetSalesUnit: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesUnitsDropdown`,
    GetProductGroup: `${BASEURL}/api/factory/execute/Appmakerz-Test/getProductGroupsDropdown`,
    GetUnitData: `${BASEURL}/api/factory/execute/Exact-Test/getUnitsDropdown`,
    GetSalesData: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrders`,
    GetSalesData6months: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrders6months`,
    GetCustomerData: `${BASEURL}/api/factory/execute/Exact-Test/getCustomersDropdown`,
    PostSalesOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/postSalesOrder`,
    GetSalesDetail: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrder`,
    GetSalesItems: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesItemsDropdown`,
    GetSalesItemsPrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesPrice`,
    PostSalesOrderLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/postSalesOrderLine`,
    GetSalesOrderLines: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrderLines`,
    GetSuppliersDropdown: `${BASEURL}/api/factory/execute/Exact-Test/getSuppliersDropdown`,
    GetJournalsDropdown: `${BASEURL}/api/factory/execute/Exact-Test/getJournalsDropdown`,
    PostPurchaseOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseOrder`,
    GetPurchaseOrders: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrders`,
    GetPurchaseOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrder`,
    GetPurchaseItems: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseItemsDropdown`,
    GetGlCode: `${BASEURL}/api/factory/execute/Exact-Test/getGlCodeDropdown`,
    GetVATCode: `${BASEURL}/api/factory/execute/Exact-Test/getVATCodeDropdown`,
    GetPurchasePrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchasePrice`,
    PostPurchaseOrderLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseOrderLine`,
    PutPurchaseOrderLineVat: `${BASEURL}/api/factory/execute/Appmakerz-Test/putPurchaseOrderLineVat`,
    GetPurchaseOrderLineManualVat: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrderLineManualVat`,
    GetPurchaseOrderLines: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrderLines`,
    GetTransportPrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportPrices`,
    PostPricechangeTask: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPricechangeTask`,
    GetTasks: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTasks`,
    GetTaskById: `${BASEURL}/api/factory/execute/appmakerz-test/getTaskById`,
    GetTasktypeDropdown: `${BASEURL}/api/factory/execute/appmakerz-test/getTasktypeDropdown`,
    GetTaskstatusDropdown: `${BASEURL}/api/factory/execute/appmakerz-test/getTaskstatusDropdown`,
    GetProductsDropdown: `${BASEURL}/api/factory/execute/appmakerz-test/getProductsDropdown`,
    GetUsersDropdown: `${BASEURL}/api/factory/execute/appmakerz-test/getUsersDropdown`,
    PutTask: `${BASEURL}/api/factory/execute/appmakerz-test/putTask`,
    ApprovePurchasePrice: `${BASEURL}/api/factory/execute/appmakerz-Test/ApprovePurchasePrice`,
    GetProduct: `${BASEURL}/api/factory/execute/Appmakerz-Test/getProduct`,
    GetPurchasePrices: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchasePrices`,
    GetSalesPrices: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesPrices`,
    GetTransportPrices: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportPrices`,
    ApproveSalesPrice : `${BASEURL}/api/factory/execute/Appmakerz-Test/ApproveSalesPrice`,
    ApproveTransportPrice : `${BASEURL}/api/factory/execute/Appmakerz-Test/ApproveTransportPrice`,
    FileUpload : `${BASEURL}/api/factory/upload-file`,
    GetPurchaseOrderDropdown : `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrderDropdown`,
    PostTransportPrice : `${BASEURL}/api/factory/execute/Appmakerz-Test/postTransportPrice`,
    GenerateProductXmlExact : `${BASEURL}/api/exact/GenerateProductXmlExact`,
    PostPurchaseDocument : `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseDocument`,
    GeneratePurchaseInvoiceXmlExact : `${BASEURL}/api/exact/GeneratePurchaseInvoiceXmlExact`,
    GenerateSalesInvoiceXmlExact : `${BASEURL}/api/exact/GenerateSalesInvoiceXmlExact`,
    PutPurchaseOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/putPurchaseOrder`,
    PutSalesOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/putSalesOrder`,
    PostProductsExact: `${BASEURL}/api/factory/execute/Appmakerz-Test/postProductsExact`,
    PostProductsExactSend: `${BASEURL}/api/factory/execute/Appmakerz-Test/postProductsExactSend`,
    PostPurchaseOrderExact: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseOrderExact`,
    PostPurchaseOrderExactSend: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseOrderExactSend`,
    PostSalesOrderExact: `${BASEURL}/api/factory/execute/Appmakerz-Test/postSalesOrderExact`,
    PostSalesOrderExactSend: `${BASEURL}/api/factory/execute/Appmakerz-Test/postSalesOrderExactSend`,
    GetDocumenttypesDropdown: `${BASEURL}/api/factory/execute/Appmakerz-Test/getDocumenttypesDropdown`,
    PostOrderDocument: `${BASEURL}/api/factory/execute/Appmakerz-Test/postOrderDocument`,
    GetSalesPrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesPrice`,
    PostTransports: `${BASEURL}/api/factory/execute/Appmakerz-Test/postTransports`,
    PutSalesOrderLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/putSalesOrderLine`,
    GetSalesOrderTransports: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrderTransports`,
    GetOrderLinesByPacking: `${BASEURL}/api/factory/execute/Appmakerz-Test/getOrderLinesByPacking`,
    GetOrderLinesByContainer: `${BASEURL}/api/factory/execute/Appmakerz-Test/getOrderLinesByContainer`,
    GetOrderLinesByShipping: `${BASEURL}/api/factory/execute/Appmakerz-Test/getOrderLinesByShipping`,
    GetOrderLinesByOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/getOrderLinesByOrder`,
    GetTransportLinesByOrder : `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportLinesByOrder`,
    PostPurchaseLines : `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseLines`,
    PutSalesPurchaseId : `${BASEURL}/api/factory/execute/Appmakerz-Test/putSalesPurchaseId`,
    GetQualityControl : `${BASEURL}/api/factory/execute/Appmakerz-Test/getQualityControl`,
    CompleteOrder : `${BASEURL}/api/factory/execute/Appmakerz-Test/completeOrder`,
    GetTransportersDropdown : `${BASEURL}/api/factory/execute/exact-test/getTransportersDropdown`,
    GetTransportLinesByShipping: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportLinesByShipping`,
    GetTransportLinesByContainer: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportLinesByContainer`,
    GetTransportLinesByPacking: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportLinesByPacking`,
    DeleteSalesOrderLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteSalesOrderLine`,
    PutTransports: `${BASEURL}/api/factory/execute/Appmakerz-Test/putTransports`,
    DeleteTransports: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteTransports`,
    PostPurchaseOrderLineTransport: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseOrderLineTransport`,
    PutTransportPurchaseId: `${BASEURL}/api/factory/execute/Appmakerz-Test/putTransportPurchaseId`,
    PutProduct: `${BASEURL}/api/factory/execute/Appmakerz-Test/putProduct`,
    GetTransports: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransports`,
    GetPurchaseDescription: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseDescription`,
    GetPurchaseUnit: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseUnit`,
    GetSalesUnitData: `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesUnit`,
    GetPurchaseTransportManual: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseTransportManual`,
    PostPurchaseTransportManual: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchaseTransportManual`,
    PutPurchaseTransportManual: `${BASEURL}/api/factory/execute/Appmakerz-Test/putPurchaseTransportManual`,
    DeletePurchaseTransportManual: `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePurchaseTransportManual`,
    GetDefaultVatCode  : `${BASEURL}/api/factory/execute/exact-test/getDefaultVatCode`,
    PostPurchasePrice  : `${BASEURL}/api/factory/execute/Appmakerz-Test/postPurchasePrice`,
    PostSalesPrice  : `${BASEURL}/api/factory/execute/Appmakerz-Test/postSalesPrice`,
    PutPurchasePrice  : `${BASEURL}/api/factory/execute/Appmakerz-Test/putPurchasePrice`,
    GetPurchaseLinesToChange  : `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseLinesToChange`,
    UpdatePricePurchaseLines  : `${BASEURL}/api/factory/execute/Appmakerz-Test/updatePricePurchaseLines`,
    PutSalesPrice  : `${BASEURL}/api/factory/execute/Appmakerz-Test/putSalesPrice`,
    GetSalesLinesToChange   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesLinesToChange`,
    UpdatePriceSalesLines   : `${BASEURL}/api/factory/execute/Appmakerz-Test/updatePriceSalesLines`,
    PutTransportPrice   : `${BASEURL}/api/factory/execute/Appmakerz-Test/putTransportPrice`,
    GetTransportLinesToChange   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getTransportLinesToChange`,
    UpdateTransportPriceLines   : `${BASEURL}/api/factory/execute/Appmakerz-Test/updateTransportPriceLines`,
    DeleteSalesPrice   : `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteSalesPrice`,
    DeletePurchasePrice   : `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePurchasePrice`,
    GetDemurrage   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getDemurrage`,
    CheckMultipleLines   : `${BASEURL}/api/factory/execute/Appmakerz-Test/checkMultipleLines`,
    CheckMultipleLinesTransport   : `${BASEURL}/api/factory/execute/Appmakerz-Test/checkMultipleLinesTransport`,
    GetSalesOrdersExact   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesOrdersExact`,
    GetPurchaseOrdersExact   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseOrdersExact`,
    DeletePurchaseOrderLine   : `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePurchaseOrderLine`,
    CheckMultipleInvoice   : `${BASEURL}/api/factory/execute/Appmakerz-Test/checkMultipleInvoice`,
    GetSupplierDescription   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getSupplierDescription`,
    GetSalesDocuments   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getSalesDocuments`,
    GetPurchaseDocuments   : `${BASEURL}/api/factory/execute/Appmakerz-Test/getPurchaseDocuments`,
    GetDownloadFile   : `${BASEURL}/api/factory/download-file/`,
    GetRederijDropdown: `${BASEURL}/api/factory/execute/Exact-Test/getRederijDropdown`,
    GetAddress: `${BASEURL}/api/factory/execute/appmakerz-test/getAddress`,
    GetLines: `${BASEURL}/api/factory/execute/appmakerz-test/getLines`,
    DeleteSalesOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteSalesOrder`,
    DeleteSalesDocument: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteSalesDocument`,
    DeletePurchaseDocument: `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePurchaseDocument`,
    DeletePurchaseOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePurchaseOrder`,
    GetUserRole: `${BASEURL}/api/Roles`,
    PostTextLines: `${BASEURL}/api/factory/execute/Appmakerz-Test/postTextLines`,
    GetTextLines: `${BASEURL}/api/factory/execute/Appmakerz-Test/getTextLines`,
    PutTextLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/putTextLine`,
    DeleteTextLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteTextLine`,
    GetMonthEnd: `${BASEURL}/api/factory/execute/Appmakerz-Test/getMonthEnd`,
    CopySalesOrder: `${BASEURL}/api/factory/execute/Appmakerz-Test/copySalesOrder`,
    GetPriceChangeData: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPricesTable?filterDate=`,
    UpdatePricesTable: `${BASEURL}/api/factory/execute/Appmakerz-Test/updatePricesTable`,
    postPeriodeDatum: `${BASEURL}/api/factory/execute/Appmakerz-Test/postPeriodeDatum`,
    GetPeriodeDatum: `${BASEURL}/api/factory/execute/Appmakerz-Test/getPeriodeDatum`,
    DeletePeriodeDatum: `${BASEURL}/api/factory/execute/Appmakerz-Test/deletePeriodeDatum`,
    PutPeriodeDatum: `${BASEURL}/api/factory/execute/Appmakerz-Test/putPeriodeDatum`,
    PostDeleteProduct: `${BASEURL}/api/factory/execute/Appmakerz-Test/deleteProduct`,
    PostCheckDuplicateProduct: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicateProduct`,
    PostProductDocument: `${BASEURL}/api/factory/execute/Appmakerz-Test/postProductDocument`,
    GetProductDocuments: `${BASEURL}/api/factory/execute/Appmakerz-Test/getProductDocuments`,
    PostCheckPurchasePrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicatePurchasePrice`,
    PostCheckPurchasePriceEdit: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicatePurchasePriceEdit`,
    PostCheckSalesPrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicateSalesPrice`,
    PostCheckSalesPriceEdit: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicateSalesPriceEdit`,
    PostChecktransportPrice: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicateTransportPrice`,
    PostChecktransportPriceEdit: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkDuplicateTransportPriceEdit`,
    PostcheckPacking: `${BASEURL}/api/factory/execute/Appmakerz-Test/checkPacking`,
    PutPurchaseOrderLine: `${BASEURL}/api/factory/execute/Appmakerz-Test/putPurchaseOrderLine`,
    ChangePassword: `${BASEURL}/api/Account/ChangePassword`
  }; 
  