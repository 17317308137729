import React from 'react';
import * as authAction  from '../../actions/authAction';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ListErrors from '../../components/listerrors';
import { trls } from '../../components/translate';
import Pageloadspinner from '../../components/page_load_spinner';
import SessionManager from '../../components/session_manage';
import Axios from 'axios';
import API from '../../components/api'
import Sweetalert from 'sweetalert';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    authLogin: (params) =>
              dispatch(authAction.fetchLoginData(params)),
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            error: null, 
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const clientFormData = new FormData(event.target);
        const data = {};
        for (let key of clientFormData.keys()) {
            data[key] = clientFormData.get(key);
        }

        const username = window.localStorage.getItem('session_username');
        data['username'] = username;

        this.changePassword(data);
    }

  changePassword = (params) => {
    const headers = SessionManager.shared().getAuthorizationHeader();
    const main = this;

    Axios.post(API.ChangePassword, params, headers)
    .then(() => {
        Sweetalert({
            title: trls('Success'),
            icon: "success",
            button: "Inloggen",
        }).then(function() {
            const loginParams = {
              username: window.localStorage.getItem('session_username'),
              password: params["NewPassword"],
            };

            main.props.authLogin(loginParams);
        });
    }).catch(function (error) {
        if (error.response) {
            const err = error.response.data.ModelState[Object.keys(error.response.data.ModelState)[0]];
            main.setState({error: trls(err[0])});

            // Clear error
            setTimeout(function() {
                main.setState({error: null});
            }, 5000);
        }
    });
  }

  togglePasswordEye = (element) => {
    const wrapper = element.target.parentElement.parentElement;
    const password = wrapper.querySelector('input');
    const passwordEye = wrapper.querySelector('[name="password-eye"]');

    if(password.getAttribute("type") === "password") {
      password.setAttribute("type", "text");
      passwordEye.src = require('../../assets/images/password-eye-show.png');
    } else {
      password.setAttribute("type", "password");
      passwordEye.src = require('../../assets/images/password-eye-hide.png');
    }
  }

  render() {
    
    return (
      <div className="container p-0 vh-100 bg-white">
        <Row className="h-100">
          <Col xs={12} md={6} className="p-0 position-relative logo-sm h-md-100">
            <img src={require('../../assets/images/line-bg.png')} alt="background" className="loginBackground"/>
            <div className="login-logo">
              <a href="/login">
                <img src={require('../../assets/images/logo.svg')} alt="logo"/>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} className="bg-white">
            <Form className="h-100" onSubmit = { this.handleSubmit }>
              <div className="login__right w-100 h-100 login-page d-flex justify-content-center">
                <div className="login__form-area my-sm-auto w-sm-100 w-md-75 text-left">
                  <h1 className="text-dark">Wachtwoord bijwerken</h1>
                  <ListErrors errors={this.state.error}/>
                  <Form.Group controlId="current_password" className="password-wrapper">
                      <label htmlFor="password" className="mt-4 mt-md-5">Huidig wachtwoord</label>
                      <Form.Control type="password" name="OldPassword" className="form-control form-control-lg" required placeholder={trls("Password")} />
                      <div className="password-eye" name="toggle-password" onClick={(event)=>this.togglePasswordEye(event)}>
                        <img src={require('../../assets/images/password-eye-hide.png')} alt="password-eye" name="password-eye" className="h-100"/>
                      </div>
                  </Form.Group>
                  
                  <Form.Group controlId="new_password" className="password-wrapper">
                      <label htmlFor="password" className="mt-4 mt-md-5">Nieuw wachtwoord</label>
                      <Form.Control type="password" name="NewPassword" className="form-control form-control-lg" required placeholder={trls("Password")} />
                      <div className="password-eye" name="toggle-password" onClick={(event)=>this.togglePasswordEye(event)}>
                        <img src={require('../../assets/images/password-eye-hide.png')} alt="password-eye" name="password-eye" className="h-100"/>
                      </div>
                  </Form.Group>

                  <Form.Group controlId="confirm_password" className="password-wrapper">
                      <label htmlFor="password" className="mt-4 mt-md-5">Herhaal nieuw wachtwoord</label>
                      <Form.Control type="password" name="ConfirmPassword" className="form-control form-control-lg" required placeholder={trls("Password")} />
                      <div className="password-eye" name="toggle-password" onClick={(event)=>this.togglePasswordEye(event)}>
                        <img src={require('../../assets/images/password-eye-hide.png')} alt="password-eye" name="password-eye" className="h-100"/>
                      </div>
                  </Form.Group>

                  <Button variant="primary" size="lg" type="submit" className="mt-5" onClick={()=>this.setState({modalResumeShow: true})}>Bijwerken</Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        <Pageloadspinner/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
